<template>
  <div class="withdraw">
    <div class="wrap">
      <div class="h5-page-top">
        <img class="back" src="../../public/images/asset/my-income/back.png" alt="" @click="$router.go(-1)" />
        <span>{{ $t("asset.desc67") }}</span>
        <img class="record" @click="goRecord" src="../../public/images/asset/withdraw/record.png" alt="" />
      </div>
      <div class="wrap-title">
        <span @click="$router.go(-1)">{{ $t("asset.desc101") }}</span>
        > {{ $t("asset.desc67") }}
      </div>
      <div class="wrap-con flex">
        <div class="wrap-left">
          <div class="info-name">{{ $t("asset.desc71") }}</div>
          <div class="select-address">
            <input type="text" v-model="num" :placeholder="$t('withdraw.desc1', {
              n1: configInfo.min,
              n2: configInfo.coinName,
            })
              " @change="onChage" />
          </div>
          <div class="balance">
            {{ $t("withdraw.desc") }}<span>{{ configInfo.balance }} </span>{{ configInfo.coinName }}
          </div>

          <div class="info-name">{{ $t("withdraw.desc2") }}</div>
          <div class="info-select">
            <div class="select-check">
              <img :src="iconItem.withdrawCoinIcon" alt="" />
              {{ iconItem.withdrawCoinName }}
            </div>
            <div class="select-icon"></div>
            <div class="select-list">
              <!-- <div class="select-search">
                <div class="name">{{ $t('swap.desc6') }}</div>
                <div class="input flex">
                  <img src="../../public/images/new/searchicon1.png" alt="" />
                  <input type="text" :placeholder="$t('asset.desc59')" v-model="searchText" />
                </div>
              </div> -->
              <div class="list">
                <!-- <div class="list-info flex" v-for="item in iconList" :key="item.coinIcon" v-if="searchText == ''"
                  @click="chooseBiItem(item.coinId)">
                  <img :src="item.coinIcon" alt="" /> {{ item.withdrawCoinName }}
                </div> -->
                <div class="list-info flex" v-for="item in iconList" :key="item.id" @click="
                  chooseBiItem(item.withdrawCoinId, item.withdrawCoinName)
                  ">
                  <img :src="item.withdrawCoinIcon" alt="" />
                  {{ item.withdrawCoinName }}
                </div>
              </div>
            </div>
          </div>
          <div class="info-tips">
            {{ $t("withdraw.desc3", { n1: configInfo.coinName }) }}
          </div>

          <div class="info-name">{{ $t("withdraw.desc4") }}</div>
          <div class="info-ex">
            <div class="ie-item" v-for="(item, i) in iconItem.withdrawChainList" :key="i"
              :class="{ on: chainIndex == item.chainId }" @click="chooseChain(item.chainId, item.tokenType)">
              {{ item.tokenType }}
            </div>
          </div>

          <div class="info-name">{{ $t("withdraw.desc5") }}</div>
          <div class="select-address">
            <input type="text" v-model="address" :placeholder="$t('withdraw.desc6')" />
          </div>

          <div class="info-price flex">
            <div class="price">
              <div>{{ free }} {{ configInfo.coinName }}</div>
              <p>{{ $t("withdraw.desc7") }}</p>
            </div>
            <div class="price">
              <div>{{ totalMoney }} {{ configInfo.coinName }}</div>
              <p>{{ $t("withdraw.desc8") }}</p>
            </div>
          </div>
          <div class="select-btn flexcenter" @click="handlerShow">
            {{ $t("asset.desc75") }}
          </div>
        </div>
        <div class="wrap-right" v-if="lang == 'en_US'">
          <div class="right-name">{{ $t("withdraw.desc10") }}</div>
          <div class="right-tips">{{ $t("withdraw.desc11") }}</div>
          <div class="right-tips" v-html='$t("withdraw.desc12")'></div>
          <div class="right-tips" v-html='$t("withdraw.desc13", {
            n1: configInfo.min,
            n2: configInfo.coinName,
          })'></div>
          <div class="right-tips" v-html='$t("withdraw.desc14", { n1: iconName, n2: chainName })'></div>
          <div class="right-tips">
            {{ $t("withdraw.desc15") }}
          </div>
        </div>
        <div class="wrap-right" v-else>
          <div class="right-name">{{ $t("withdraw.desc10") }}</div>
          <div class="right-tips">{{ $t("withdraw.desc11") }}</div>
          <div class="right-tips" v-html='$t("withdraw.desc12")'></div>
          <div class="right-tips" v-html='$t("withdraw.desc13")'></div>
          <div class="right-tips" v-html='$t("withdraw.desc14", {
            n1: configInfo.min,
            n2: configInfo.coinName,
          })'></div>
          <div class="right-tips" v-html='$t("withdraw.desc15", { n1: iconName, n2: chainName })'></div>
        </div>
      </div>
      <div class="wrap-record">
        <div class="record-title flex">
          <div>{{ $t("asset.desc9") }}</div>
          <!-- <p class="flex" @click="$router.push('/record?type=2')">{{ $t('asset.desc66') }} <img
              src="../../public/images/new/righticon.png" alt="" /></p> -->
        </div>
        <div class="myincome-table">
          <div class="table-thead flex">
            <div>{{ $t("record.desc16") }}</div>
            <div>{{ $t("record.desc5") }}</div>
            <div>{{ $t("asset.desc78") }}</div>
            <div>{{ $t("withdraw.desc33") }}</div>
            <div>{{ $t("record.desc18") }}</div>
            <div>{{ $t("withdraw.desc34") }}</div>
            <div>{{ $t("withdraw.desc35") }}</div>
            <div>{{ $t("withdraw.desc36") }}</div>
            <div>{{ $t("withdraw.desc37") }}</div>
          </div>
          <div class="table-tbody">
            <div class="tbody-list flex" v-for="item in list" :key="item.id">
              <div>{{ dealAddress(item.orderNo) }}
                <img class="copy" src="../../public/images/new/copy.png" @click="copy($event, item.orderNo)" />
              </div>
              <div>{{ item.datetime }}</div>
              <div>{{ dealAddress(item.address) }}
                <img class="copy" src="../../public/images/new/copy.png" @click="copy($event, item.address)" />
              </div>
              <div>{{ item.txHash && item.txHash.length > 6 ? dealAddress(item.txHash) : '--' }}
                <img class="copy" src="../../public/images/new/copy.png" @click="copy($event, item.txHash)" />
              </div>
              <div><img class="bi" :src="item.coinIcon" alt="">{{ item.coinName }}</div>
              <div>{{ common.cutXiaoNum1(item.amount) }} {{ item.feeCoinName }}</div>
              <div>{{ item.chainName }}</div>
              <div>{{ item.fee }} {{ item.feeCoinName }}</div>
              <div style="color:#C9FA5B" v-if="item.status == 0">{{ dealAuditStatus(item.auditStatusCode, item.status) }}
              </div>
              <div style="color:#E15757" v-else-if="item.status == 2">{{ dealAuditStatus(item.auditStatusCode,
                item.status) }}</div>
              <div v-else>{{ dealAuditStatus(item.auditStatusCode, item.status) }}</div>
            </div>
          </div>
          <div class="no-data" v-if="showNull">
            <img src="../../public/images/no-data.png" alt="" />
            <span>{{ $t("record.desc13") }}</span>
          </div>
        </div>
        <div class="planet-page">
          <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="pageSize" :current-page="page"
            :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <psdModal @handlerClose="handlerClose" @handler="handler" v-if="showPsd" /> -->
    <el-dialog v-model="showPsd" :append-to-body="true">
      <div class="modal-con">
        <div class="mc-title">
          <span>{{ $t("withdraw.desc16") }}</span>
          <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="handlerClose" />
        </div>
        <div class="mc-til flex" v-if="payType == 1">{{ $t("withdraw.desc17") }} <p @click="showType(2)">{{
          $t('asset.desc140') }}</p>
        </div>
        <div class="mc-til flex" v-if="payType == 2">{{ $t("asset.desc142") }} <p @click="showType(1)">{{
          $t('asset.desc141') }}</p>
        </div>
        <div class="mc-box" v-if="payType == 1">
          <input type="text" v-model="emailShow" disabled />
        </div>
        <div class="mc-til" v-if="payType == 1">{{ $t("withdraw.desc18") }}</div>
        <div class="mc-box" v-if="payType == 1">
          <input type="text" :placeholder="$t('withdraw.desc19')" maxlength="6" v-model="emailCode" />
          <div class="mc-send" v-if="isHasSend">{{ sendTime }}</div>
          <div class="mc-send" v-else @click="handleSendEmail">
            {{ $t("withdraw.desc20") }}
          </div>
        </div>
        <div class="mc-box" v-if="payType == 2">
          <input :type="psdtype" :placeholder="$t('asset.desc143')" maxlength="6"
            onKeyUp="value=value.replace(/[^0-9]/g,'')" v-model="tradeCode" />
          <img
            :src="psdtype == 'password' ? require('../../public/images/ceyes.png') : require('../../public/images/eyesopen.png')"
            alt="" @click="handlerEye" />
        </div>
        <div class="mc-til" v-if="verifyGoogle && payType == 2">
          {{ $t("withdraw.desc21") }}
        </div>
        <div class="mc-box" v-if="verifyGoogle && payType == 2">
          <input type="text" :placeholder="$t('withdraw.desc22')" maxlength="6" v-model="googleCode" />
        </div>
        <div class="mc-bt" @click="handleWithdraw">
          {{ $t("withdraw.desc23") }}
        </div>
      </div>
    </el-dialog>
    <!-- 资金密码 -->
    <el-dialog v-model="showTrade" :append-to-body="true">
      <div class="modal-con1">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showTrade = false" />
        <div class="modal-title">{{ $t("node.desc45") }}</div>
        <div class="modal-desc">{{ $t("node.desc46") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showTrade = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(1)">
            {{ $t("node.desc48") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 谷歌密码 -->
    <el-dialog v-model="showGoogle" :append-to-body="false">
      <div class="modal-con1">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showGoogle = false" />
        <div class="modal-title">{{ $t("home.desc64") }}</div>
        <div class="modal-desc">{{ $t("asset.desc144") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showGoogle = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(2)">
            {{ $t("node.desc48") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bus from "@/utils/bus";
import Clipboard from "@/utils/libs/clipboard";
import psdModal from "@/componets/psdmodal.vue";
import { mapState } from "vuex";
export default {
  components: {
    psdModal,
  },
  data() {
    return {
      showTrade: false,
      showGoogle: false,
      lang: 'en_US',
      showPsd: false,
      isShowBiListPopup: false,
      isShowChainListPopup: false,
      isShowBiListPopup: false,
      iconId: "",
      iconName: "",
      iconItem: {},
      iconList: [],
      chainList: [],
      chainName: "",
      chainItem: {},
      chainInfo: {},
      searchText: "",
      coinShowList: [],
      configInfo: {},
      iconInfo: {},
      address: "",
      num: "",
      password: "",
      isWithdrawIng: false,
      chainNameType: "",
      free: 0,
      totalMoney: 0,
      list: [],
      showNull: false,
      totals: 0,
      finished: false,
      loading: false,
      page: 1,
      pageSize: 10,
      chainIndex: "",
      chainName: "",
      verifyGoogle: false,
      email: "",
      emailShow: "",
      emailCode: "",
      googleCode: "",
      isHasSend: false,
      sendTime: 60,
      payType: 1,
      tradeCode: '',
      psdtype: 'password',
      userInfo: {},
      isHasSend: false,
    };
  },
  watch: {
    searchText(val) {
      this.selectCoin(val);
    },
  },
  computed: {
    ...mapState(["userData"]),
  },
  mounted() {
    let _this = this;
    // this.userInfo = this.userData;
    _this.$post(_this.URL.user.info, {}).then(result => {
        if (result.code == 0) {
          _this.userInfo = result.data;
        }
      }
    )
    this.lang = localStorage.getItem('language') || 'en_US';
    // this.getCoinList()
    this.email = this.userData.email;
    this.emailShow = this.dealEmail(this.userData.email);
    this.getConfig();
    this.getRecord();
  },
  methods: {
    handlerSubmit(i) {
      this.showTrade = false;
      this.showGoogle = false;
      this.handlerClose(0);
      if (localStorage.getItem('ismobile') == 1) {
        if (i == 1) {
          this.$router.push('/user/info?id=2')
        } else {
          this.$router.push('/user/info?id=4')
        }
      } else {

        bus.emit("hadleropen", i);
      }


      return;
    },
    handlerEye() {

      this.psdtype == 'password' ? this.psdtype = 'text' : this.psdtype = 'password'

    },
    showType(i) {
      this.userInfo = this.$store.state.userData;
      if (i == 2 && !this.userInfo.hasBindGoogleAuth) {
        this.showGoogle = true;
        return;
      }
      if (!this.userInfo.hasSetPassword && i == 2) {
        this.showTrade = true;
        return;
      }
      this.payType = i;
      this.isHasSend = false;
      this.isHasSend1 = false;
      this.email = '';
      this.emailCode = '';
      this.googleCode = '';
      this.tradeCode = '';
      if (i == 1) {
        this.email = this.userData.email;
      }
    },
    handlerCurrent(val) {
      this.page = val;
      this.getRecord();
    },
    dealAddress(str) {
      if (str && str.length > 18) {
        return (
          str.substring(0, 6) +
          "...." +
          str.substring(str.length - 6, str.length)
        );
      } else {
        return str;
      }
    },
    dealAuditStatus(auditStatusCode, status) {
      if (auditStatusCode === 1) {
        switch (status) {
          case 0:
            return this.$t("withdraw.desc39");
          case 1:
            return this.$t("withdraw.desc40");
          case 2:
            return this.$t("withdraw.desc41");

          default:
            break;
        }
      } else {
        switch (auditStatusCode) {
          case 0:
            return this.$t("withdraw.desc24");
          case 2:
            return this.$t("withdraw.desc25");
        }
      }
    },

    copy(e, text) {
      // @click="copy($event,googleRandom.secretKey)"
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t("planet.desc61"));
      });
    },
    getRecord() {
      this.$post(this.URL.assets.withdrawRecord, {
        page: this.page,
        pageSize: this.pageSize,
        coinId: this.$route.query.id,
      }).then((res) => {
        if (res.code == 0) {
          res.data.list.forEach(e => {
            e.datetime = this.common.formatDate(e.txDate);
          });
          this.list = res.data.list;
          this.totals = parseFloat(res.data.page.count);
          if (this.list.length == 0) {
            this.showNull = true;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    goRecord() {
      this.$router.push({
        path: "/withdraw/record",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    handlerClose() {
      this.showPsd = false;
    },
    dealEmail(str) {
      if (str) {
        if (str.indexOf("@") !== -1) {
          let b = str.split("@");
          return b[0][0] + "*****" + b[0][b[0].length - 1] + "@" + b[1];
        } else {
          return str;
        }
      } else {
        return str;
      }
    },
    handleSendEmail() {
      if (this.isHasSend1) {
        return;
      }
      this.isHasSend1 = true;
      this.$post(this.URL.login.sendverify, {
        accountType: 1, // 验证类型(0-手机,1-(邮箱))
        account: this.email,
        type: 10,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t("sign.desc37"));
          this.isHasSend = true;
          this.sendTime = 60;
          this.startSetTime();
        } else {
          this.isHasSend1 = false;
          this.$message.error(res.message);
        }
      });
    },
    startSetTime() {
      let that = this;
      if (this.sendTime == 0) {
        this.isHasSend = false;
        this.isHasSend1 = false;
      } else {
        setTimeout(() => {
          that.sendTime--;
          that.startSetTime();
        }, 1000);
      }
    },
    handler(val) {

      this.password = val.psd;
      this.googleCode = val.google;
      this.handleWithdraw();
    },
    handlerShow() {

      let _this = this;

      if (this.address == "") {
        this.$message.error(this.$t("asset.desc70"));
        return;
      }
      if (this.num == "" || this.num < parseFloat(this.configInfo.min)) {
        this.$message.error(
          this.$t("asset.desc72", {
            n1: this.configInfo.min,
            n2: this.iconItem.coinName,
          })
        );
        return;
      }
      if (this.num > parseFloat(this.configInfo.balance)) {
        this.$message.error(this.$t("pool.desc68"));
        return;
      }
      _this.$post(_this.URL.user.info, {}).then(result => {
        if (result.code == 0) {
          _this.userInfo = result.data;
          if (!_this.userInfo.hasBindGoogleAuth) {
            _this.showGoogle = true;
            return;
          }

          _this.googleCode = "";
          _this.emailCode = "";
          _this.tradeCode = '';
          _this.isHasSend = false;
          _this.showPsd = true;
        }
      })

    },
    onChage(val) {
      if (this.num < parseFloat(this.configInfo.min)) {
        this.$message.error(
          this.$t("asset.desc72", {
            n1: this.configInfo.min,
            n2: this.iconItem.coinName,
          })
        );
        return;
      }
      if (this.num > parseFloat(this.configInfo.balance)) {
        this.$message.error(this.$t("withdraw.desc9"));
        return;
      }
      // let free = this.num * parseFloat(this.configInfo.coinPrice) * parseFloat(this.configInfo.feeRate), free1 = free / parseFloat(this.configInfo.feeCoinPrice);
      // if (free1 < parseFloat(this.configInfo.feeMin)) {
      //   this.free = this.configInfo.feeMin
      // } else {
      //   this.free = this.common.cutXiaoNum(free1, 7)
      // }
      // this.totalMoney = this.num - this.free
      this.$post(this.URL.assets.withdrawCal, {
        coinId: this.$route.query.id,
        accountType: this.$route.query.type,
        amount: this.num,
        withdrawCoinId: this.iconId,
        chainId: this.chainIndex,
      }).then((res) => {
        if (res.code == 0) {
          this.free = this.common.cutXiaoNum1(res.data.feeAmount);
          this.totalMoney = this.common.cutXiaoNum1(res.data.arriveAmount);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    selectCoin(name) {
      if (name) {
        let arr = this.iconList;
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].coinName.toUpperCase().indexOf(name.toUpperCase()) != -1) {
            newArr.push(arr[i]);
          }
        }
        this.coinShowList = newArr;
      } else {
        this.coinShowList = this.iconList;
      }
    },
    getConfig() {
      this.$post(this.URL.assets.withdraw_config, {
        coinId: this.$route.query.id,
        // chainName: this.chainName,
        accountType: this.$route.query.type,
      }).then((res) => {
        if (res.code == 0) {
          res.data.balance = this.common.cutXiaoNum1(res.data.balance)
          this.configInfo = res.data;
          this.verifyGoogle = res.data.verifyGoogle;
          this.iconList = res.data.withdrawCoinList;
          this.iconItem = res.data.withdrawCoinList[0];
          this.iconId = this.iconItem.withdrawCoinId;
          this.iconName = this.iconItem.withdrawCoinName;
          this.chainIndex = this.iconItem.withdrawChainList[0].chainId;
          this.chainName = this.iconItem.withdrawChainList[0].tokenType;
          this.free = 0;
          this.num = "";
          this.totalMoney = 0;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleWithdraw() {
      if (this.payType == 2 && this.tradeCode == '') {
        this.$message.error(this.$t('asset.desc143'))
        return;
      }
      if (this.payType == 2 && this.googleCode == '') {
        this.$message.error(this.$t('withdraw.desc22'))
        return;
      }
      if (this.payType == 1 && this.emailCode == '') {
        this.$message.error(this.$t('withdraw.desc19'))
        return;
      }
      if (this.isWithdrawIng) return;
      this.isWithdrawIng = true;
      let password = '';
      if (this.payType == 2) {
        password = this.userInfo.sourceType == 2 || this.userInfo.sourceType == 3
          ? this.tradeCode
          : this.common.mdpassword(this.tradeCode);
        this.email = '';
      }
      this.$post(this.URL.assets.withdraw, {
        coinId: this.$route.query.id,
        withdrawCoinId: this.iconId,
        chainName: this.chainName,
        email: this.email,
        emailCode: this.emailCode,
        amount: this.num,
        googleCode: this.payType == 2 ? this.googleCode : '',
        toAddress: this.address,
        accountType: this.$route.query.type,
        transactionPassword: password
      }).then((res) => {
        this.isWithdrawIng = false;
        if (res.code == 0) {
          this.$message.success(this.$t("asset.desc48"));
          this.num = "";
          this.totalMoney = 0;
          this.free = 0;
          this.googleCode = "";
          this.emailCode = "";
          this.address = "";
          this.tradeCode = '';
          this.showPsd = false;
          this.page = 1;
          this.list = [];
          this.getRecord();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    copy(e, text) {
      // @click="copy($event,googleRandom.secretKey)"
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t("planet.desc61"));
      });
    },
    qrBack(dataUrl, id) {
      // 缓存二维码数据
      this.qrImg = dataUrl;
    },
    downQr() {
      // 下载二维码
      let name = new Date().getTime();
      let a = document.createElement("a");
      a.style.display = "none";
      a.download = name;
      a.href = this.qrImg;
      document.body.appendChild(a);
      a.click();
      this.$message.success(this.$t("asset.desc49"));
    },
    chooseBiItem(id, name) {
      // this.isShowBiListPopup = false
      this.iconId = id;
      this.iconName = name;
      let arr = this.iconList;
      for (let i = 0; i < arr.length; i++) {
        if (id == arr[i].withdrawCoinId) {
          this.iconItem = arr[i];
          this.chainIndex = this.iconItem.withdrawChainList[0].chainId;
          this.chainName = this.iconItem.withdrawChainList[0].tokenType;
          break;
        }
      }
    },
    chooseChain(id, name) {
      this.chainIndex = id;
      this.chainName = name;
    },
    jump_link(url, type) {
      this.$router.replace({
        path: url,
        query: {
          type: type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.withdraw {
  padding: 116px 0 69px;
  width: 100%;
  min-height: 100vh;
  background: url("../../public/images/new/landbg.png") center no-repeat;
  background-size: 100% 100%;

  .wrap {
    margin: 0 auto;
    width: 1240px;

    .h5-page-top {
      display: none;
    }

    .wrap-title {
      color: #fff;
      font-size: 13px;

      span {
        color: #a0a0a0;
        cursor: pointer;
      }
    }

    .wrap-con {
      margin-top: 32px;
      justify-content: space-between;

      .wrap-left {
        flex: 0 0 481px;

        .info-name {
          font-size: 18px;
          color: #ffffff;
          line-height: 18px;
          margin-bottom: 22px;
        }

        .info-ex {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-bottom: 24px;

          .ie-item {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ffffff33;
            width: 105px;
            height: 40px;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
            cursor: pointer;

            &.on {
              color: #c9fa5b;
              position: relative;
              border: 1px solid #c9fa5b;

              &::before {
                position: absolute;
                right: 0;
                bottom: 0;
                display: block;
                content: "";
                width: 16px;
                height: 16px;
                background: url("../../public/images/asset/withdraw/check.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }

        .info-tips {
          font-size: 16px;
          font-weight: 400;
          color: #c9fa5b;
          margin-bottom: 24px;
        }

        .info-price {
          margin-top: 24px;

          .price {
            flex: 1;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);

            div {
              margin-bottom: 8px;
              font-size: 16px;
              color: #fff;
            }
          }
        }

        .select-btn {
          margin-top: 32px;
          width: 193px;
          height: 48px;
          background: url("../../public/images/new/btnbg2.png") center no-repeat;
          background-size: 100% 100%;
          font-size: 16px;
          color: #000000;
          font-weight: 600;
          cursor: pointer;
        }

        .info-select {
          position: relative;
          padding: 12px 16px;
          height: 48px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          margin-bottom: 14px;

          &:hover {
            border: 1px solid #c9fa5b;

            .select-icon {
              transform: rotate(0deg);
              transition: 0.5s;
            }

            .select-list {
              display: block;
            }
          }

          .select-icon {
            position: absolute;
            top: 21px;
            right: 16px;
            width: 10px;
            height: 6px;
            background: url("../../public/images/new/downicon1.png") center no-repeat;
            background-size: 100% 100%;
            transform: rotate(180deg);
            transition: 0.5s;
          }

          .select-list {
            position: absolute;
            top: 48px;
            left: 0;
            padding: 24px 0 20px;
            width: 100%;
            background: #181818;
            z-index: 56;
            display: none;

            .select-search {
              padding: 0 32px 20px;

              .name {
                font-size: 18px;
                color: #ffffff;
              }

              .input {
                margin-top: 20px;
                padding: 13px 12px;
                border: 1px solid rgba(255, 255, 255, 0.1);
                box-sizing: border-box;

                img {
                  flex: 0 0 18px;
                  width: 18px;
                  height: 18px;
                  margin-right: 6px;
                }

                input {
                  flex: 1;
                  height: 18px;
                  font-size: 16px;
                  color: #fff;

                  &::placeholder {
                    color: rgba(255, 255, 255, 0.6);
                  }
                }
              }
            }

            .list-info {
              padding: 12px 32px;
              font-size: 16px;
              color: #fff;
              line-height: 24px;
              cursor: pointer;

              &:hover {
                background: #292929;
              }

              img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .select-check {
            width: 100%;
            display: flex;
            font-size: 16px;
            color: #fff;
            line-height: 24px;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }

        .select-address {
          margin-bottom: 14px;
          padding: 12px 16px;
          height: 48px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;

          input {
            width: 100%;
            height: 22px;
            font-size: 16px;
            color: #fff;

            &::placeholder {
              color: #4f4f4f;
            }
          }
        }

        .balance {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 24px;

          span {
            color: #c9fa5b;
            padding-right: 5px;
          }
        }
      }

      .wrap-right {
        flex: 0 0 420px;
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        height: fit-content;
        border-radius: 8px;

        .right-name {
          font-size: 18px;
          color: #ffffff;
          margin-bottom: 20px;
        }

        .right-tips {
          font-size: 14px;
          color: #a8a8a8;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .record-title {
      margin: 40px 0 16px;
      justify-content: space-between;
      font-size: 18px;
      color: #ffffff;
      line-height: 23px;

      p {
        font-size: 14px;
        cursor: pointer;

        img {
          width: 5px;
          height: 8px;
          margin: 7px 0 0 6px;
        }
      }
    }

    .myincome-table {
      margin-top: 24px;

      .table-thead {
        padding: 20px 24px;
        background: #181818;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);

        div {
          flex: 1;

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            flex: 0 0 120px;
          }

          &:last-child {
            flex: 0 0 80px;
            text-align: right;
          }
        }
      }

      .table-tbody {
        .tbody-list {
          padding: 22px 24px;
          font-size: 13px;
          color: #bbbbbb;
          border-bottom: 1px solid #0e0e0e;

          &:hover {
            background: #181818;
          }

          div {
            flex: 1;
            display: flex;
            align-items: center;

            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;

              &.copy {
                cursor: pointer;
              }
            }

            .bi {
              width: 18px;
              height: 18px;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              flex: 0 0 120px;
            }

            &:last-child {
              flex: 0 0 80px;
              cursor: pointer;
              justify-content: flex-end;
            }
          }
        }
      }

      .no-data {
        width: 100%;
        padding: 130px 0;

        img {
          display: block;
          width: 120px;
          height: 120px;
          margin: 0 auto 16px;
        }

        span {
          display: block;
          margin: 0 auto;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #a1a1a1;
        }
      }
    }

    .planet-page {
      padding: 0;
      margin-top: 40px;

      ::v-deep .el-pagination {
        button {
          background-color: rgba(255, 255, 255, 0);
          color: #666666;
          font-size: 14px;
        }

        .el-pager {
          li {
            background: rgba(255, 255, 255, 0);
            font-size: 14px;
            color: #666666;
          }

          .is-active {
            background: #c9fa5b !important;
            border-radius: 4px !important;
            color: #000000 !important;
          }
        }
      }
    }
  }
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .mc-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    span {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
    }

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .mc-til {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #fff;
    justify-content: space-between;

    p {
      color: #C9FA5B;
      cursor: pointer;
    }
  }

  .mc-box {
    position: relative;
    margin-bottom: 24px;

    input {
      width: 100%;
      height: 52px;
      border: 1px solid #393939;
      padding-left: 16px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    img {
      position: absolute;
      top: 50%;
      right: 15px;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .mc-send {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 16px;
      font-weight: 400;
      color: #c9fa5b;
      cursor: pointer;
      text-align: right;
      line-height: 20px;
    }
  }

  .mc-bt {
    margin-top: 32px;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../public/images/asset/withdraw/modal-bt.png") no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
  }
}

.modal-con1 {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .divestment-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;
    flex-wrap: wrap;

    .divestment-info {
      margin-bottom: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 1;
      }

      p {
        flex: 0 0 40%;
        color: #fff;
        text-align: right;
      }
    }
  }

  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }

  .modal-tips1 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

@media (max-width: 767px) {
  .withdraw {
    padding: 0 16px 16px;

    .wrap {
      width: 100%;

      .h5-page-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;

        .back {
          width: 14px;
          height: 22px;
        }

        span {
          font-size: 16px;
          font-weight: 500;
          color: #fff;
        }

        .record {
          width: 20px;
          height: 20px;
        }
      }

      .wrap-title,
      .wrap-record {
        display: none;
      }

      .wrap-con {
        margin-top: 12px;
        display: block;

        .wrap-left {
          margin-bottom: 20px;

          .select-btn {
            font-size: 16px;
            font-weight: 600;
            width: 343px;
            height: 52px;
            background: url("../../public/images/asset/withdraw/modal-bt2.png") center no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  .modal-con {
    width: calc(100vw - 8px);
    padding: 16px;
  }

  .modal-con1 {
    width: calc(100vw - 8px);
    padding: 16px;

    .close {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .modal-title {
      margin-bottom: 32px;
      font-size: 20px;
      color: #ffffff;
    }

    .modal-desc {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.8);
    }

    .divestment-list {
      padding: 20px;
      border-radius: 8px;
      background: #242424;
      flex-wrap: wrap;

      .divestment-info {
        margin-bottom: 16px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 14px;

        &:last-child {
          margin-bottom: 0;
        }

        div {
          flex: 1;
        }

        p {
          flex: 0 0 40%;
          color: #fff;
          text-align: right;
        }
      }
    }

    .modal-tipsname {
      margin: 21px 0 11px;
      font-size: 14px;
      color: #fff;
      line-height: 16px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }

    .modal-tips1 {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 14px;

      div {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .modal-input {
      margin: 20px 0 16px;
    }

    .modal-tips {
      font-size: 14px;
      color: #eb4343;
    }

    .modal-btncon {
      margin-top: 24px;
      height: 50px;
      background: url("../../public/images/new/btnbg16.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #000000;
      cursor: pointer;
    }

    .modal-btn {
      margin-top: 32px;
      justify-content: space-between;

      div {
        flex: 0 0 48%;
        height: 44px;
        background: url("../../public/images/new/btnbg14.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;

        &:last-child {
          background: url("../../public/images/new/btnbg15.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;
        }
      }
    }
  }
}
</style>