<template>
  <div class="modal">
    <van-popup v-model:show="showPsd" round>
      <div class="modal-con">
        <img
          src="../public/images/new/closeicon.png"
          class="close"
          @click="handlerClose"
        />
        <div class="modal-title">{{ $t("asset.desc77") }}</div>
        <div class="modal-name">{{ $t("swap.desc2") }}</div>
        <div class="modal-input">
          <input
            type="password"
            :placeholder="$t('swap.desc3')"
            v-model="password"
          />
        </div>
        <div class="modal-name">{{ $t("home.desc16") }}</div>
        <div class="modal-input">
          <input
            type="text"
            :placeholder="$t('home.desc17')"
            v-model="google"
          />
        </div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="handlerClose">
            {{ $t("home.desc45") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit">
            {{ $t("home.desc46") }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPsd: true,
      password: "",
      google: "",
    };
  },
  methods: {
    handlerClose() {
      this.$emit("handlerClose");
    },
    handlerSubmit() {
      if (this.password == "") {
        this.$message.error(this.$t("swap.desc3"));
        return;
      }
      if (this.google == "") {
        this.$message.error(this.$t("home.desc17"));
        return;
      }
      this.$emit("handler", {
        psd: this.password,
        google: this.google,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.modal-con {
  position: relative;
  padding: 32px;
  width: 400px;
  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }
  .modal-name {
    margin-bottom: 12px;
    font-size: 16px;
    color: #ffffff;
  }
  .modal-input {
    margin-bottom: 18px;
    padding: 16px;
    border: 1px solid #393939;
    border-radius: 4px;
    input {
      width: 100%;
      height: 20px;
      font-size: 16px;
      color: #fff;
      &::placeholder {
        color: #5f5f5f;
      }
    }
  }
  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;
    div {
      flex: 0 0 159px;
      height: 59px;
      background: url("../public/images/new/btnbg11.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;
      &:last-child {
        background: url("../public/images/new/btnbg10.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}
</style>
